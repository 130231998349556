import {Routes} from '@angular/router';
import {AuthExternalProviders} from '../../../common/guards/auth-external-providers.guard';
import {AuthGuard} from '../../../common/guards/auth-guard.service';

export const routes: Routes = [
  {
    path: '',
    loadComponent: async () => import('./components/pages/faluche/faluche-page.component'),
  },
  {
    path: 'couture',
    children: [
      {
        title: '🪡 Couture - Faluche.app',
        path: '',
        loadComponent: async () => import('./components/pages/sewing/sewing-page.component'),
      },
      {
        path: 'heraldique-blasonnement',
        children: [
          {
            title: '🛡️Héraldique - Faluche.app',
            path: '',
            loadComponent: async () => import('./components/pages/sewing/heraldry/heraldry-page.component'),
          },
          {
            title: '🛡️Héraldique - Faluche.app',
            path: ':slug',
            loadComponent: async () => import('./components/pages/sewing/heraldry/heraldry-detail/heraldry-detail-page.component'),
          },
        ],
      },
      {
        path: ':slug',
        loadComponent: async () => import('../../../common/components/pages/markdown-render-page/markdown-render-page.component'),
      },
    ],
  },
  {
    path: 'codes',
    children: [
      {
        title: '🙈 Codes - Faluche.app',
        path: '',
        loadComponent: async () => import('./components/pages/codes/codes-page.component'),
      },
      {
        title: '🏛 Archives - Faluche.app',
        path: 'archives-national',
        loadComponent: async () => import('./components/pages/codes/archives-national/archives-national-page.component'),
      },
      {
        path: 'archives-national/:slug',
        loadComponent: async () => import('../../../common/components/pages/markdown-render-page/markdown-render-page.component'),
      },
      {
        title: '🦣 Particularités - Faluche.app',
        path: 'particularites',
        loadComponent: async () => import('./components/pages/codes/particularites/particularites-page.component'),
      },
      {
        path: 'particularites/:slug',
        loadComponent: async () => import('../../../common/components/pages/markdown-render-page/markdown-render-page.component'),
      },
      {
        title: '🤡 Ordres - Faluche.app',
        path: 'ordres',
        loadComponent: async () => import('./components/pages/codes/ordres/ordres-page.component'),
      },
      {
        path: 'ordres/:slug',
        loadComponent: async () => import('../../../common/components/pages/markdown-render-page/markdown-render-page.component'),
      },
      {
        path: 'insignes',
        children: [
          {
            title: '🎖 Insignes - Faluche.app',
            path: '',
            loadComponent: async () => import('./components/pages/codes/insignias-gallery/insignias-gallery-page.component'),
          },
          {
            title: '🎖 Insignes - Faluche.app',
            path: ':slug',
            loadComponent: async () => import('./components/pages/codes/insignias-gallery/insignia-detail/insignia-detail-page.component'),
          },
        ],
      },
      {
        title: 'Questions de code - Faluche.app',
        path: 'questions-code',
        loadComponent: async () => import('./components/pages/codes/riddles/riddles-page.component'),
      },
    ],
  },
  {
    path: 'jeux',
    children: [
      {
        title: '⚔️ Jeux - Faluche.app',
        path: '',
        loadComponent: async () => import('./components/pages/games/games-page.component'),
      },
      {
        title: '🎲 Dices - Faluche.app',
        path: 'des',
        loadComponent: async () => import('./components/pages/games/dices-roll/dices-roll-page.component'),
      },
      {
        path: ':slug',
        loadComponent: async () => import('../../../common/components/pages/markdown-render-page/markdown-render-page.component'),
      },
    ],
  },
  {
    path: 'pins-index',
    children: [
      {
        title: '🎖 Pins index - Faluche.app',
        path: '',
        loadComponent: async () => import('./components/pages/pins-index/pins-index-page.component'),
      },
      {
        title: '🎖 Pins index - Faluche.app',
        path: ':slug',
        loadComponent: async () => import('./components/pages/pins-index/pins-detail/pins-detail-page.component'),
      },
    ],
  },
  {
    path: 'communaute',
    children: [
      {
        title: '🧙🏻 Communauté - Faluche.app',
        path: '',
        loadComponent: async () => import('./components/pages/community/community-page.component'),
      },
      {
        path: 'associations',
        children: [
          {
            title: '🎭 Associations - Faluche.app',
            path: '',
            loadComponent: async () => import('./components/pages/community/associations/associations-page.component'),
          },
          {
            title: '🎭 Associations - Faluche.app',
            path: ':slug',
            loadComponent: async () =>
              import('./components/pages/community/associations/association-detail/association-detail-page.component'),
          },
        ],
      },
      {
        path: 'weekends',
        children: [
          {
            title: '🍻 Weekends - Faluche.app',
            path: '',
            loadComponent: async () => import('./components/pages/community/weekends/weekend-page.component'),
          },
          {
            title: '🍻 Weekends - Faluche.app',
            path: ':slug',
            loadComponent: async () => import('./components/pages/community/weekends/weekend-detail/weekend-detail-page.component'),
          },
        ],
      },
      {
        path: 'aperals',
        children: [
          {
            title: '🎭 Apérals - Faluche.app',
            path: '',
            loadComponent: async () => import('./components/pages/community/aperals/aperals-page.component'),
          },
          {
            title: '🎭 Apérals - Faluche.app',
            path: ':slug',
            loadComponent: async () => import('./components/pages/community/aperals/aperals-detail/aperal-detail-page.component'),
          },
        ],
      },
    ],
  },
  {
    title: '✅ Checklist - Faluche.app',
    path: 'checklist',
    data: {rootPost: 'checklist-week-end-faluchard'},
    loadComponent: async () => import('../../../common/components/pages/markdown-render-page/markdown-render-page.component'),
  },
  {
    path: 'user',
    title: 'Utilisateur - Faluche.app',
    loadComponent: async () => import('./components/pages/user/user-page.component'),
    canActivate: [AuthGuard],
  },
  {
    path: 'about',
    title: '👨🏼‍💻 À propos - Faluche.app',
    loadComponent: async () => import('./components/pages/about/about-page.component'),
  },
  {
    title: '📝 Articles - Faluche.app',
    path: 'post/:slug',
    loadComponent: async () => import('../../../common/components/pages/markdown-render-page/markdown-render-page.component'),
  },
  {
    path: 'privacy-policy',
    title: '👨🏼‍💻 Privacy policy',
    loadComponent: async () => import('../../../common/components/rgpd/rgpd.component'),
  },
  {
    path: 'reset-password',
    title: 'Reset Password',
    loadComponent: async () => import('../../../common/components/pages/reset-password-page/reset-password-page.component'),
  },
  {
    path: 'provider-redirect/:provider',
    canActivate: [AuthExternalProviders],
    loadComponent: async () => import('./components/pages/faluche/faluche-page.component'),
  },
  {
    path: 'not-found',
    title: 'Contenu introuvable - Paillardes & Chants',
    loadComponent: async () => import('./components/pages/not-found-page/not-found-page.component'),
  },
  {
    path: '**',
    redirectTo: 'not-found',
  },
];
