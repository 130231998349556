<ion-app>
  <cmn-progress-bar color="primary" />
  <ion-split-pane #splitPane contentId="menu-content">
    <ion-menu #menu contentId="menu-content">
      <ion-header>
        <ion-toolbar>
          <ion-title aria-level="1" class="fal-app-component__title" role="heading" routerLink="/" slot="start">Faluche.app</ion-title>
          @if (!(splitPane.ionSplitPaneVisible | async)?.detail?.visible) {
            <cmn-switch-theme-button />
            <ion-button (click)="menu.close()" color="dark" fill="clear" slot="end">
              <ion-icon aria-label="Fermer menu" name="close-outline" size="large" slot="icon-only" />
            </ion-button>
          } @else {
            <cmn-switch-theme-button slot="end" />
          }
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list>
          <ion-menu-toggle auto-hide="false">
            <!--            <cmn-user-login-list-item />-->
            <ion-item button routerDirection="root" routerLink="/codes" routerLinkActive="selected">
              <fa-icon [fixedWidth]="true" [icon]="faBook" aria-hidden="true" size="lg" slot="start" />
              <ion-label>Codum</ion-label>
            </ion-item>
            <ion-item button routerDirection="root" routerLink="/couture" routerLinkActive="selected">
              <fa-icon [fixedWidth]="true" [icon]="faHandScissors" aria-hidden="true" size="lg" slot="start" />
              <ion-label>Guide de couture</ion-label>
            </ion-item>
            <ion-item button routerDirection="root" routerLink="/jeux" routerLinkActive="selected">
              <fa-icon [fixedWidth]="true" [icon]="faWineBottle" aria-hidden="true" size="lg" slot="start" />
              <ion-label>Jeux à boire</ion-label>
            </ion-item>
            <ion-item button routerDirection="root" routerLink="/checklist" routerLinkActive="selected">
              <fa-icon [fixedWidth]="true" [icon]="faTasks" aria-hidden="true" size="lg" slot="start" />
              <ion-label>Checklist de congrès</ion-label>
            </ion-item>
            <ion-item button routerDirection="root" routerLink="/pins-index" routerLinkActive="selected">
              <fa-icon [fixedWidth]="true" [icon]="faBook" aria-hidden="true" size="lg" slot="start" />
              <ion-label>Pins index</ion-label>
              <ion-badge color="primary" slot="end">Beta</ion-badge>
            </ion-item>
            <ion-item button routerDirection="root" routerLink="/communaute" routerLinkActive="selected">
              <fa-icon [fixedWidth]="true" [icon]="faGlassCheers" aria-hidden="true" size="lg" slot="start" />
              <ion-label>Communauté</ion-label>
            </ion-item>
            <ion-item href="https://paillardes.app">
              <ion-icon aria-hidden="true" class="fal-app-component__paillardes_icon" size="large" src="assets/img/paillardes-icon.svg" />
              <ion-label>Paillardes.app</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
        <div class="fal-app-component__side-menu-icon-container">
          <img alt="logo faluche.app" class="fal-app-component__app-icon" height="128" ngSrc="assets/icons/icon.svg" priority width="128" />
        </div>
      </ion-content>
      <ion-footer>
        <ion-item button lines="none" routerDirection="root" routerLink="/about" routerLinkActive="selected">
          <fa-icon [fixedWidth]="true" [icon]="'question'" size="lg" slot="start" />
          <ion-label>À propos</ion-label>
        </ion-item>
      </ion-footer>
    </ion-menu>
    <ion-router-outlet id="menu-content" />
  </ion-split-pane>
  @if (showFabContactButtons) {
    <cmn-fab-contact
      [facebookMessengerLink]="environment.facebookMessengerLink"
      [instagramDirectMessageLink]="environment.instagramDirectMessageLink"
      [mailLink]="environment.mailContact"
      [whatsAppLink]="environment.whatsAppLink"
    />
  }
</ion-app>
