import {AsyncPipe, DOCUMENT, NgOptimizedImage} from '@angular/common';
import {AfterViewInit, ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {faBook, faGlassCheers, faHandScissors, fas, faTasks, faWineBottle} from '@fortawesome/free-solid-svg-icons';
import {initFbSDK} from '../../../common/utils/facebook-sdk-init';
import {environment} from '../environments/environment';
import {FaConfig, FaIconComponent, FaIconLibrary} from '@fortawesome/angular-fontawesome';
import {PwaService} from '../../../common/services/pwa.service';
import {far} from '@fortawesome/free-regular-svg-icons';
import {fab} from '@fortawesome/free-brands-svg-icons';
import {
  IonApp,
  IonBadge,
  IonButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonRouterOutlet,
  IonSplitPane,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import {ProgressBarComponent} from '../../../common/components/progress-bar/progress-bar.component';
import {SwitchThemeButtonComponent} from '../../../common/components/switch-theme-button/switch-theme-button.component';
import {FabContactComponent} from '../../../common/components/fab-contact/fab-contact.component';
import {RouterLink, RouterLinkActive} from '@angular/router';
import {addIcons} from 'ionicons';
import {
  caretDownCircle,
  closeOutline,
  funnel,
  images,
  informationCircleOutline,
  list,
  logoAngular,
  logoFacebook,
  logoInstagram,
  logoIonic,
  mail,
  warning,
} from 'ionicons/icons';

@Component({
  selector: 'fal-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    IonApp,
    ProgressBarComponent,
    IonSplitPane,
    IonMenu,
    IonHeader,
    IonToolbar,
    IonTitle,
    SwitchThemeButtonComponent,
    IonButton,
    IonIcon,
    IonContent,
    IonList,
    IonMenuToggle,
    IonItem,
    FaIconComponent,
    IonLabel,
    IonBadge,
    IonFooter,
    IonRouterOutlet,
    FabContactComponent,
    RouterLink,
    NgOptimizedImage,
    AsyncPipe,
    RouterLinkActive,
  ],
})
export class AppComponent implements AfterViewInit {
  readonly showFabContactButtons = !environment.featureFlipping.facebookChat;
  readonly environment = environment;
  readonly faGlassCheers = faGlassCheers;
  readonly faBook = faBook;
  readonly faTasks = faTasks;
  readonly faWineBottle = faWineBottle;
  readonly faHandScissors = faHandScissors;

  constructor(
    @Inject('WINDOW') private readonly window: Window,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly faIconLibrary: FaIconLibrary,
    private readonly faConfig: FaConfig,
    private readonly pwaService: PwaService,
  ) {
    addIcons({
      closeOutline,
      list,
      images,
      informationCircleOutline,
      mail,
      logoFacebook,
      logoInstagram,
      logoAngular,
      logoIonic,
      funnel,
      caretDownCircle,
      warning,
    });
    this.pwaService.init();
    this.faIconLibrary.addIconPacks(fas, far, fab);
    this.faConfig.defaultPrefix = 'fas';
    this.faConfig.fallbackIcon = faBook;
  }

  ngAfterViewInit(): void {
    if (environment.featureFlipping.facebookChat) {
      initFbSDK(this.document, this.window, environment.facebookPageId, environment.facebookAppId);
    }
  }
}
